<template>
  <div>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="'https://servo.blob.core.windows.net/photos/' + registrant.photo"
                  :text="avatarText(registrant.name)"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ registrant.name }}
                    </h4>
                    <span class="card-text">{{ registrant.email }}</span>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="LinkIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ registrant.connectGroupCategory }}
                    </h5>
                    <small>Penggembalaan</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    :variant="cgPresenceResult(cgPresencePercentage)"
                    rounded
                  >
                    <feather-icon
                      icon="BarChartIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ cgPresencePercentage }}%
                    </h5>
                    <small>Percentage of CG Presence</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Age</span>
                  </th>
                  <td class="pb-50">
                    {{ moment().format('YYYY') - moment(registrant.birthDate).format('YYYY') }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Gender</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ registrant.gender }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ChevronsUpIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">CG Level</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ registrant.connectGroupLevel }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="SmileIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">CG Name</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.connectGroupName }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Phone</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.phone }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="HomeIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Service Category</span>
                  </th>
                  <td class="pb-50">
                    {{ registrant.serviceCategory }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">First Option Schedule</span>
                  </th>
                  <td
                    v-if="(registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) && (registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule)"
                    class="pb-50"
                  >
                    <li>Audisi: {{ moment(registrant.firstOptionMinistrySchedule.auditionSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.auditionRoom }})</li>
                    <li>Interview: {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})</li>
                  </td>
                  <td
                    v-else-if="(registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) && ((registrant.firstOptionMinistrySchedule.interviewRoom && !registrant.firstOptionMinistrySchedule.interviewSchedule) || (!registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule) || (!registrant.firstOptionMinistrySchedule.interviewRoom && !registrant.firstOptionMinistrySchedule.interviewSchedule))"
                  >
                    <li>Audisi: {{ moment(registrant.firstOptionMinistrySchedule.auditionSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.auditionRoom }})</li>
                    <li>Interview: Dihubungi PIC</li>
                  </td>
                  <td
                    v-else-if="((registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule) || (!registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) || (!registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule)) && (registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule && firstDivisionType === 2)"
                  >
                    {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})
                  </td>
                  <td
                    v-else-if="((registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule) || (!registrant.firstOptionMinistrySchedule.auditionRoom && registrant.firstOptionMinistrySchedule.auditionSchedule) || (!registrant.firstOptionMinistrySchedule.auditionRoom && !registrant.firstOptionMinistrySchedule.auditionSchedule)) && (registrant.firstOptionMinistrySchedule.interviewRoom && registrant.firstOptionMinistrySchedule.interviewSchedule)"
                  >
                    <li>Audisi: Dihubungi PIC</li>
                    <li>Interview: {{ moment(registrant.firstOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.firstOptionMinistrySchedule.interviewRoom }})</li>
                  </td>
                  <td v-else>
                    <li>Audisi: Dihubungi PIC</li>
                    <li>Interview: Dihubungi PIC</li>
                  </td>
                </tr>
                <tr
                  v-if="registrant.secondOptionMinistry"
                >
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Second Option Schedule</span>
                  </th>
                  <td
                    v-if="registrant.secondOptionMinistry"
                    class="pb-50"
                  >
                    {{ moment(registrant.secondOptionMinistrySchedule.interviewSchedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ registrant.secondOptionMinistrySchedule.interviewRoom }})
                  </td>
                  <td
                    v-else-if="!registrant.secondOptionMinistrySchedule.interviewRoom || !registrant.secondOptionMinistrySchedule.interviewSchedule"
                  >
                    Dihubungi PIC
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-27">
            <h5 class="mb-0">
              Current Status
            </h5>
            <b-badge
              variant="light-info"
              style="font-size: 10px;"
            >
              {{ registrant.servolutionRegistrationStatus.name }}
            </b-badge>
            <small class="text-muted w-100">{{ moment().format("dddd, DD MMMM YYYY") }}</small>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle">First Option: {{ registrant.firstMinistry.name }}</span>
              </li>
              <li class="my-25">
                <span class="align-middle">Second Option: {{ registrant.secondMinistry ? registrant.secondMinistry.name: '-' }}</span>
              </li>
              <li>
                <span class="align-middle">Approved By: {{ registrant.leaderPeople.name }} ({{ registrant.leaderPeople.cellPhone }})</span>
              </li>
            </ul>
            <b-badge
              variant="light-primary"
              class="d-block"
            >
              Registered on {{ moment(registrant.createdAt).format("DD MMMM YYYY") }}
            </b-badge>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="12"
      >
        <b-card title="CG Recommendation">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`1. Apakah ${registrant.name} dapat menjalin hubungan / kerjasama yang baik dengan setiap orang dalam anggota CG atau dengan pemimpin ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.menjalinHubunganKerjaSamaDiCg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`2. Komentar / catatan dari pemimpin`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.leaderNote"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="12"
      >
        <b-card title="Initial Application Form">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`1. Arti pelayanan bagi ${registrant.name} secara pribadi`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.artiPelayanan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`2. Alasan kenapa ${registrant.name} ingin melayani di bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.alasanPelayanan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`3. ${registrant.name} pernah mempunyai pengalaman di bidang ini sebelumnya ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.pengalamanBidang"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`4. Lama ${registrant.name} menekuni bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.berapaLamaMenekuniBidangIni"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`5. Ada prestasi yang pernah dicapai dalam bidang yang diikuti ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.prestasiDalamBidang"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`6. Bersedia meluangkan waktu minimal 1x dalam 1 minggu di malam hari untuk menghadiri pertemuan - pertemuan terkait dengan pelayanan yang dipilih ? (online / onsite)`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.bersediaMeluangkanWaktu"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`7. Keluarga mendukung dalam pelayanan ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.keluargaMendukung"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`8. Tempat belajar ${registrant.name} di dalam menambah pengetahuan di bidang ini`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.dimanaBelajarBidang"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`9. Hobi`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.hobby"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`10. Aktivitas / keanggotaan ${registrant.name} sebagai bentuk nyata dari hobi`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.aktivitasNyataHobby"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`11. Kegiatan yang menyita waktu dalam keseharian`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.kegiatanMenyitaWaktu"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`12. Apakah kegiatan di atas mempengaruhi kegiatan pelayanan ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.kegiatanMempengaruhiPelayanan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`13. Yang ingin dicapai ${registrant.name} dalam hidup secara pribadi dalam 5 tahun ke depan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.apaYangInginDicapaiDalam_5Tahun"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`14. Susunan prioritas hidup ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.susunanPrioritas"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`15. 3 kekuatan dan kelemahan ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.kekuatanKelemahan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`16. Talenta`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.talenta"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`17. Talenta yang dimiliki apakah bisa menjadi motivasi ${registrant.name} untuk menjadi volunteer di bidang pelayanan yang dipilih sekarang`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.talentaMenjadiMotivasi"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`18. Skill khusus atau keahlian yang membuat ${registrant.name} berbeda dengan lainnya`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.skillKhusus"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`19. Pendidikan terakhir ${registrant.name} dan dimana menempuh pendidikan tersebut ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.pendidikanTerakhir"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`20. Value hidup dari ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.valueHidup"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`21. Pergumulan yang sedang dihadapi ${registrant.name} saat ini`"
                  class="font-weight-bold"
                >
                  <span class="font-italic">Hubungan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanHubungan }}</span><br>
                  <span class="font-italic">Pekerjaan / Studi</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanPekerjaanStudi }}</span><br>
                  <span class="font-italic">Kesehatan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanKesehatan }}</span><br>
                  <span class="font-italic">Keuangan</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pergumulanKeuangan }}</span><br>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`22. Apakah ${registrant.name} RUTIN melakukan SAAT TEDUH pribadi ?`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.rutinSaatTeduh"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`23. 3 nama pemimpin rohani / orang tua rohani / teman rohnai yang mengenal dan memperhatikan pertumbuhan rohani ${registrant.name}`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.namaRohaniawan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`24. Ayat Favorit`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.ayatFavorit ? registrant.ayatFavorit : '-'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`25. Pendapat tentang hal - hal di bawah ini.`"
                  class="font-weight-bold"
                >
                  <span class="font-italic">Mengkomsumsi Minuman Beralkohol</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatMinumanBeralkohol }}</span><br>
                  <span class="font-italic">Merokok</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatMerokok }}</span><br>
                  <span class="font-italic">Perceraian dan pernikahan kembali</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatPerceraian }}</span><br>
                  <span class="font-italic">Aborsi karena terpaksa</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatAborsi }}</span><br>
                  <span class="font-italic">Homoseksual</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatHomoseksual }}</span><br>
                  <span class="font-italic">Hutang - hutang pribadi</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatHutangPribadi }}</span><br>
                  <span class="font-italic">Tatoo</span>
                  <span>
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="18"
                    />
                  </span>
                  <span class="font-italic">{{ registrant.pendapatTatoo }}</span><br>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`26. Status`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`27. Nama Pasangan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="registrant.namaPasangan ? registrant.namaPasangan: '-'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId === 7 || registrant.servolutionRegistrationStatusId === 8 || registrant.servolutionRegistrationStatusId === 34">
      <b-col
        cols="12"
        lg="12"
      >
        <b-card title="Interview Summary Report">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`1. Dasar Kerohanian`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.dasarKerohanian"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Catatan untuk Dasar Kerohanian`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.dasarKerohanianNotes === '' ? '-' : interviewSummaryReport.dasarKerohanianNotes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`2. Apakah ada masalah hidup yang mendalam`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.tidakAdaMasalahHidup"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Catatan untuk Apakah ada masalah hidup yang mendalam`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.tidakAdaMasalahHidupNotes === '' ? '-' : interviewSummaryReport.tidakAdaMasalahHidupNotes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`3. Komitmen dalam Pelayanan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.komitmenDalamPelayanan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Catatan untuk Komitmen dalam Pelayanan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.komitmenDalamPelayananNotes === '' ? '-' : interviewSummaryReport.komitmenDalamPelayananNotes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`4. Tanggung Jawab dalam Kehidupan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.tanggungJawabDalamKehidupan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Catatan untuk Tanggung Jawab dalam Kehidupan`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.tanggungJawabDalamKehidupanNotes === '' ? '-' : interviewSummaryReport.tanggungJawabDalamKehidupanNotes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`5. Kedewasaan dalam Cara Berpikir`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.kedewasaanBerpikir"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Catatan untuk Kedewasaan dalam Cara Berpikir`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.kedewasaanBerpikirNotes === '' ? '-' : interviewSummaryReport.kedewasaanBerpikirNotes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Nama Interviewer`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.namaInterviewer"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`Acc. Nama Kadept`"
                  class="font-weight-bold"
                >
                  <b-form-input
                    plaintext
                    class="font-italic"
                    :value="interviewSummaryReport.accNamaKadept"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.secondMinistry && registrant.servolutionRegistrationStatusId === 2">
      <b-col
        cols="12"
        lg="12"
      >
        <b-card title="Audition">
          <validation-observer ref="auditionForm">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Audition Result"
                    rules="required"
                  >
                    <b-form-group
                      label="Lulus Audisi ?"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="auditionResult"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="auditionResult"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    class="mr-1"
                    type="submit"
                    @click.prevent="validationAuditionForm"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{ name: 'servolution-participants', params: { id: eventId } }"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.secondMinistry && registrant.servolutionRegistrationStatusId === 4">
      <b-col cols="12">
        <b-card
          text-variant="center"
          class="card card-congratulations"
        >
          <!-- images -->
          <b-img
            src="@/assets/images/elements/decore-left.png"
            class="congratulations-img-left"
          />
          <b-img
            src="@/assets/images/elements/decore-right.png"
            class="congratulations-img-right"
          />
          <!--/ images -->

          <b-avatar
            variant="success"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="CheckIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Pass the Audition
          </h1>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.secondMinistry && registrant.servolutionRegistrationStatusId === 6">
      <b-col cols="12">
        <b-card
          text-variant="center"
          bg-variant="secondary"
        >
          <b-avatar
            variant="danger"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="XIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Audition Failed
          </h1>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId === 7">
      <b-col cols="12">
        <b-card
          text-variant="center"
          class="card card-congratulations"
        >
          <!-- images -->
          <b-img
            src="@/assets/images/elements/decore-left.png"
            class="congratulations-img-left"
          />
          <b-img
            src="@/assets/images/elements/decore-right.png"
            class="congratulations-img-right"
          />
          <!--/ images -->

          <b-avatar
            variant="success"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="CheckIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Pass
          </h1>
          <b-card-text class="m-auto w-75">
            For <strong>{{ finalDivisionName }}</strong> division
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="registrant.servolutionRegistrationStatusId === 8">
      <b-col cols="12">
        <b-card
          text-variant="center"
          bg-variant="secondary"
        >
          <b-avatar
            variant="danger"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="XIcon"
            />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            Interview Failed
          </h1>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="(!registrant.secondMinistry && registrant.servolutionRegistrationStatusId === 2) || registrant.servolutionRegistrationStatusId === 4 || registrant.servolutionRegistrationStatusId === 6 || registrant.servolutionRegistrationStatusId === 34">
      <b-col
        cols="12"
        lg="12"
      >
        <b-card title="Interview Summary">
          <validation-observer ref="interviewSummaryForm">
            <b-form>
              <b-row>
                <b-col
                  v-if="registrant.servolutionRegistrationStatusId !== 34"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Dasar Kerohanian"
                    rules="required"
                  >
                    <b-form-group
                      label="1. Dasar Kerohanian"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="dasarKerohanian"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="dasarKerohanian"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        v-model="dasarKerohanianNotes"
                        rows="3"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="registrant.servolutionRegistrationStatusId !== 34"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Apakah ada masalah hidup yang mendalam"
                    rules="required"
                  >
                    <b-form-group
                      label="2. Apakah ada masalah hidup yang mendalam"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="tidakAdaMasalahHidup"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="tidakAdaMasalahHidup"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        v-model="tidakAdaMasalahHidupNotes"
                        rows="3"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="registrant.servolutionRegistrationStatusId !== 34"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Komitmen dalam Pelayanan"
                    rules="required"
                  >
                    <b-form-group
                      label="3. Komitmen dalam Pelayanan"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="komitmenDalamPelayanan"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="komitmenDalamPelayanan"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        v-model="komitmenDalamPelayananNotes"
                        rows="3"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="registrant.servolutionRegistrationStatusId !== 34"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggung Jawab dalam Kehidupan"
                    rules="required"
                  >
                    <b-form-group
                      label="4. Tanggung Jawab dalam Kehidupan"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="tanggungJawabDalamKehidupan"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="tanggungJawabDalamKehidupan"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        v-model="tanggungJawabDalamKehidupanNotes"
                        rows="3"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="registrant.servolutionRegistrationStatusId !== 34"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kedewasaan dalam Cara Berpikir"
                    rules="required"
                  >
                    <b-form-group
                      label="5. Kedewasaan dalam Cara Berpikir"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="kedewasaanBerpikir"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="kedewasaanBerpikir"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        v-model="kedewasaanBerpikirNotes"
                        rows="3"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col cols="12">
                  <b-form-group
                    label="Nama Interviewer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Interviewer"
                      rules="required"
                    >
                      <b-form-input
                        v-model="namaInterviewer"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Acc. Nama Kadept"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Acc. Nama Kadept"
                      rules="required"
                    >
                      <b-form-input
                        v-model="accNamaKadept"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col
                  v-if="role === 'PIC Ministry'"
                  cols="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Interview Result"
                    rules="required"
                  >
                    <b-form-group
                      label="Lulus Interview ?"
                      :state="errors.length > 0 ? false:null"
                    >
                      <div
                        class="demo-inline-spacing"
                        style="margin-top: -15px;"
                      >
                        <b-form-radio
                          v-model="interviewResult"
                          value="ya"
                          class="custom-control-primary"
                        >
                          Ya
                        </b-form-radio>
                        <b-form-radio
                          v-model="interviewResult"
                          value="tidak"
                          class="custom-control-secondary"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    class="mr-1"
                    type="submit"
                    @click.prevent="validationInterviewForm"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{ name: 'servolution-participants', params: { id: eventId } }"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BAvatar, BCard, BCardHeader, BCardBody, BBadge, BForm, BFormInput, BFormGroup, BFormRadio, BFormTextarea, BButton, BFormInvalidFeedback, BImg, BCardText,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Loading from 'vue-loading-overlay'
import * as moment from 'moment'
import _ from 'lodash'
import CryptoJS from 'crypto-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BImg,
    BCardText,

    Loading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      moment,
      eventId: '',
      registrant: null,
      firstDivisionType: null,
      interviewSummaryReport: {},
      cgPresencePercentage: 0,
      dasarKerohanian: '',
      dasarKerohanianNotes: '',
      tidakAdaMasalahHidup: '',
      tidakAdaMasalahHidupNotes: '',
      komitmenDalamPelayanan: '',
      komitmenDalamPelayananNotes: '',
      tanggungJawabDalamKehidupan: '',
      tanggungJawabDalamKehidupanNotes: '',
      kedewasaanBerpikir: '',
      kedewasaanBerpikirNotes: '',
      namaInterviewer: '',
      auditionResult: '',
      interviewResult: '',
      accNamaKadept: '',
      finalDivisionName: '',
      role: '',
      authorizedName: '',
      required,
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  async mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    this.role = userData[0].role
    this.authorizedName = userData[0].name

    // Get the user data
    const registrantDetails = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${this.$route.params.id}`)
    this.registrant = registrantDetails.data
    this.eventId = this.registrant.servolutionEventsId

    // Get division type
    const division = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/church/ministry/${registrantDetails.data.servolutionEvents.churchId}`)
    this.firstDivisionType = _.find(division.data, { divisionsId: registrantDetails.data.firstOptionMinistry }).ministryTypeId

    // Check status and role
    if (registrantDetails.data.servolutionRegistrationStatusId === 34 && this.role === 'PIC Servolution Assessment') {
      return this.$router.replace({ name: 'misc-not-authorized' })
    }

    // Get cg presence percentage
    const cgPresence = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrant/cg/presence/${this.registrant.personId}`)
    this.cgPresencePercentage = cgPresence.data[0].result

    // Get summary report
    if (this.registrant.servolutionRegistrationStatusId === 7 || this.registrant.servolutionRegistrationStatusId === 8 || this.registrant.servolutionRegistrationStatusId === 34) {
      const interviewReport = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summary/${this.$route.params.id}`)
      this.interviewSummaryReport = interviewReport.data

      // Get final division if pass the interview
      if (this.registrant.servolutionRegistrationStatusId === 7) {
        const divisionResult = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/volunteer/new/${this.registrant.personId}`)
        this.finalDivisionName = divisionResult.data[0].division
      }
    }

    // Stop loading
    this.isLoading = false
  },
  methods: {
    cgPresenceResult(result) {
      let badge = 'light-danger'
      if (result >= 81.25) {
        badge = 'light-success'
      } else if (result >= 56.25) {
        badge = 'light-info'
      } else if (result >= 31.25) {
        badge = 'light-warning'
      }

      return badge
    },
    validationAuditionForm() {
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()

      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.auditionForm.validate().then(success => {
        if (success) {
          // Check if user has access
          if (!this.$can('update', 'servolution_assessment_audition')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Set data
          const data = {
            updatedAt: new Date(),
          }

          // Check result
          let status = 4
          let isPassedFirstOptionMinistry = true
          if (this.auditionResult === 'tidak') {
            status = 6
            isPassedFirstOptionMinistry = false
          }
          data.isPassedFirstOptionMinistry = isPassedFirstOptionMinistry
          data.servolutionRegistrationStatusId = status

          // Update
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            // Show Loading
            this.isLoading = true

            if (result.value) {
              this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/audition/${this.$route.params.id}`, data)
                .then(() => {
                  this.isLoading = false

                  // Redirect to role lists
                  this.$router.replace({ name: 'servolution-participants', params: { id: this.eventId } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Data has been saved successfully.',
                        },
                      })
                    })
                })
            } else {
              // Hide loading
              this.isLoading = false
            }
          })
        }
      })
    },
    validationInterviewForm() {
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.interviewSummaryForm.validate().then(success => {
        if (success) {
          // Check if user has access
          if (!this.$can('update', 'servolution_assessment_interview')) {
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Update
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Update it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            // Show Loading
            this.isLoading = true

            if (result.value) {
              // Set interview summary result
              const interviewSummaryData = {}

              // Nama interviewer
              if (this.role === 'PIC Servolution Assessment') {
                interviewSummaryData.namaInterviewer = this.authorizedName
              }

              // Acc Kadept
              if (_.isEmpty(this.interviewSummaryReport) && (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin')) {
                interviewSummaryData.namaInterviewer = this.authorizedName
                interviewSummaryData.accNamaKadept = this.authorizedName
              } else if (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin') {
                interviewSummaryData.accNamaKadept = this.authorizedName
              }

              // Save summary data
              if (_.isEmpty(this.interviewSummaryReport)) {
                interviewSummaryData.servolutionRegistrationsId = this.registrant.id
                interviewSummaryData.dasarKerohanian = this.dasarKerohanian
                interviewSummaryData.dasarKerohanianNotes = this.dasarKerohanianNotes
                interviewSummaryData.tidakAdaMasalahHidup = this.tidakAdaMasalahHidup
                interviewSummaryData.tidakAdaMasalahHidupNotes = this.tidakAdaMasalahHidupNotes
                interviewSummaryData.komitmenDalamPelayanan = this.komitmenDalamPelayanan
                interviewSummaryData.komitmenDalamPelayananNotes = this.komitmenDalamPelayananNotes
                interviewSummaryData.tanggungJawabDalamKehidupan = this.tanggungJawabDalamKehidupan
                interviewSummaryData.tanggungJawabDalamKehidupanNotes = this.tanggungJawabDalamKehidupanNotes
                interviewSummaryData.kedewasaanBerpikir = this.kedewasaanBerpikir
                interviewSummaryData.kedewasaanBerpikirNotes = this.kedewasaanBerpikirNotes
                await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summaries`, interviewSummaryData)
              } else {
                interviewSummaryData.updatedAt = new Date()
                await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/summaries/${this.interviewSummaryReport.id}`, interviewSummaryData)
              }

              // Set registrant data
              const registrantData = {
                updatedAt: new Date(),
              }

              // Set status based on role
              let status = 7
              if (this.role === 'PIC Ministry' || this.role === 'PIC Servolution Regional' || this.role === 'PIC Servolution Local' || this.role === 'Admin') {
                if (this.interviewResult === 'tidak') {
                  status = 8
                }
              } else if (this.role === 'PIC Servolution Assessment') {
                status = 34
              }
              registrantData.servolutionRegistrationStatusId = status

              // Check for the final division
              let finalDivision = null
              if (_.isNull(this.registrant.isPassedFirstOptionMinistry)) {
                finalDivision = this.registrant.firstOptionMinistry
              } else if (this.registrant.isPassedFirstOptionMinistry) {
                finalDivision = this.registrant.firstOptionMinistry
              } else if (!this.registrant.isPassedFirstOptionMinistry) {
                finalDivision = this.registrant.secondOptionMinistry
              }
              
              // console.log(isPassedFirstOpt)
              // if (this.registrant.isPassedFirstOptionMinistry)
              // if (this.registrant.servolutionRegistrationStatusId === 6 || this.registrant.servolutionRegistrationStatusId === 34) {
              //   finalDivision = this.registrant.secondOptionMinistry
              // }

              // Update registrant details
              this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/interview/${this.$route.params.id}/${finalDivision}`, registrantData)
                .then(() => {
                  this.isLoading = false

                  // Redirect to participants
                  this.$router.replace({ name: 'servolution-participants', params: { id: this.eventId } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Success',
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: 'Data has been saved successfully.',
                        },
                      })
                    })
                })
            } else {
              // Hide loading
              this.isLoading = false
            }
          })
        }
      })
    },
  },
}
</script>

<style>
</style>